import { useEffect, useState } from 'react';
import './../../../App.css';
import galleryData from '../../../data/galleryData';

export default function Gallery(){
    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con'>
                    <div>GALLERY</div>
                    <img src='/images/menu_banner.jpg' decoding="async"/>
                </div>
                
                <div className='detail-page__con'>
                    <div className='detail-page__txt-line'></div>
                    <div className='detail-page__txt-con'>
                        <div className='detail-page__txt-title'>갤러리</div>
                    </div>
                    
                    <div className='portfolio__con fade-in-self'>
                        <ul className='portfolio__grid-con'>
                            {
                                galleryData.map((item, i) => {
                                    return <GridItem key={i} item={item} />
                                })
                            }
                        </ul>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

function GridItem({ item }){
    const [imageUrl, setImageUrl] = useState(null);
    const extensions = ['webp', 'jpg', 'png', 'jpeg', 'gif'];
    
    useEffect(() => {
        // 이미지 존재 여부 확인 함수
        const checkImageExists = (url) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => resolve(true);
                img.onerror = () => resolve(false);
                img.src = url;
            });
        };
        
        // 여러 확장자를 시도하여 존재하는 이미지 찾기
        const findValidImage = async () => {
            let found = false;
            
            for (const ext of extensions) {
                const url = `/images/gallery/image${item.id}.${ext}`;
                const exists = await checkImageExists(url);
                if (exists) {
                    setImageUrl(url);
                    found = true;
                    break;
                }
            }
            
            // 모든 확장자를 시도해도 이미지를 찾지 못한 경우 기본값 설정
            if (!found) {
                setImageUrl(`/images/gallery/image${item.id}.jpg`);
            }
        };
        
        findValidImage();
    }, [item.id]);
    
    return (
        <li className='portfolio__grid-item'>
            <div 
                className='portfolio__img-box' 
                style={{
                    backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
                    backgroundColor: !imageUrl ? '#f0f0f0' : 'transparent' // 이미지 로딩 전 배경색
                }}
            >
            </div>
            <div className='portfolio__item-title'>
                <span>{item.title}</span>
            </div>
        </li>
    )
}