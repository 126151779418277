// src/data/galleryData.js

const galleryData = [
  {
    id: '01',
    title: ''

  },
  {
    id: '02',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '03',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '04',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '05',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '06',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '07',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '08',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '09',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '10',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '11',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '12',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '13',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '14',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '15',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '16',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '17',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '18',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '19',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '20',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '21',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '22',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '23',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '24',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  },
  {
    id: '25',
    title: '영광 낙월 풍력단지 설치선 TOC 작업'

  }
];

export default galleryData.reverse();